import React from 'react'
import { Background, IntroProjects, Layout, SectionRoadmap, SEO } from '../components'
import { graphql } from 'gatsby'

const IndexPage = ({ data }) => {

  // const {
  //   posts: { nodes: posts },
  // } = data

  const {
    projects: { nodes: projects },
  } = data 

  return (
    <Layout>
      <SEO title="Home" />
      <Background showImage title="블로그" />
      <SectionRoadmap title="글" />
      <IntroProjects projects={projects} page title="프로젝트" />
    </Layout>
  )
}

export const query = graphql`
  {
    posts: allMdx(
      sort: { fields: frontmatter___date, order: ASC }
      filter: {
        frontmatter: { division: { eq: "post" }, published: { eq: true } }
      }
    ) {
      nodes {
        frontmatter {
          title
          category
          subcategory
          slug
        }
        id
        timeToRead
      }
    }
    projects: allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: {
        frontmatter: { division: { eq: "project" }, published: { eq: true } }
      }
    ) {
      nodes {
        excerpt(pruneLength: 100, truncate: true)
        frontmatter {
          title
          subtitle
          author
          division
          category
          subcategory
          tags
          date(formatString: "YYYY-MM-DD")
          slug
          projectUrl
          githubRepositoryUrl
          featuredImage {
            childImageSharp {
              gatsbyImageData(width: 1200, placeholder: BLURRED)
            }
          }
        }
        id
        tableOfContents
        timeToRead
      }
    }
  }
`

export default IndexPage
